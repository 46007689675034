<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card class="pb-3">
      <v-card-title class="justify-center">
        <span class="headline">Nível de Acesso</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="role.name"
              :readonly="show"
              label="Nome"
              outlined
              name="name"
              type="text"
              dense
              required
              hide-details
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <treeselect
              v-model="role.permissionsIds"
              :readonly="show"
              :multiple="true"
              :options="permissionsVal"
              placeholder="Permissões"
              value-consists-of="LEAF_PRIORITY"
              required
              :rules="requiredRules"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              v-model="role.description"
              :readonly="show"
              outlined
              label="Descrição"
              rows="3"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            color="primary"
            :outlined="!show"
            class="mx-2"
            @click="$emit('close')"
          >{{ show ? 'Fechar' : 'Cancelar'}}</v-btn>
          <v-btn
            v-if="!show"
            :disabled="isLoading"
            color="primary"
            @click="role.id ? update() : save()"
          >Salvar</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { CREATE_ROLE_MUTATION, UPDATE_ROLE_MUTATION } from './../graphql/Mutation'
import { GET_PERMISSIONS_BY_TYPE_QUERY } from './../graphql/Query'
import { formatError } from '@/utils'
import { mapActions } from 'vuex'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: 'AddRoleDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog, Treeselect },
  props: {
    dialog: Boolean,
    role: Object,
    show: Boolean
  },
  data: () => ({
    error: undefined,
    isLoading: false,
    permissions: [],
    showError: false,
    showSuccess: false,
    success: undefined
  }),
  apollo: {
    permissions: {
      query: GET_PERMISSIONS_BY_TYPE_QUERY,
      variables: {
        type: 'partner'
      }
    }
  },
  computed: {
    permissionsVal () {
      return [
        {
          id: "setting",
          label: "Setting",
          children: [
            { id: this.getPermissionId("setting_list"), label: "Dispor Cofiguração", isDisabled: this.show },
          ]
        },
        {
          id: "tests",
          label: "Testes",
          children: [
            { id: this.getPermissionId("tests_list"), label: "Listar Testes", isDisabled: this.show },
          ]
        },
        {
          id: "entities",
          label: "Configuração",
          children: [
            { id: this.getPermissionId("entities_list"), label: "Dispor Cofiguração", isDisabled: this.show },
          ]
        },
        // {
        //   id: "support",
        //   label: "Suporte",
        //   children: [
        //     { id: this.getPermissionId("support_list"), label: "Dar Suporte", isDisabled: this.show },
        //   ]
        // },
      ];
    },
    requiredRules () {
      return [v => !!v || "Campo obrigatório"]
    }
  },
  methods: {
    ...mapActions({
      setRole: 'role/setRole',
      updateRoleState: 'role/updateRole'
    }),
    getPermissionId (key) {
      return this.permissions.length > 0 ? this.permissions.find(p => p.name == key).id : key
    },
    async save () {
      this.isLoading = true
      this.role.type = 'partner'
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_ROLE_MUTATION,
          variables: {
            roleInput: this.role
          }
        })
        this.setRole(data.createRole)
        this.success = 'Nivel de acesso criado com sucesso!'
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    async update () {
      this.isLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ROLE_MUTATION,
          variables: {
            id: this.role.id,
            roleInput: this.role
          }
        })
        this.updateRoleState(data.updateRole)
        this.success = 'Nivel de acesso actualizado com sucesso!'
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}

</script>

<style>
.v-input--selection-controls {
  margin-top: 0;
  margin-bottom: 0;
}

.vue-treeselect__control {
  border: 1px solid #9e9e9e;
  border-radius: 3px;
}

.vue-treeselect__placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused)
  .vue-treeselect__control:hover {
  border: 1px solid #000;
}

</style>
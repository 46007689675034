<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-text justify="center" class="pt-5 pb-0">
        <p class="text-subtitle-1 black--text text-center">
          {{$t('share_title')}}
        </p>
        <div style="margin-top: -5%; text-align:center; margin-bottom:2%" class="caption">
          {{$t('share_content')}}
        </div>
        
        <div class="d-flex flex-row pt-3">
           <v-tooltip v-model="show" top>
            <template v-slot:activator>
          <v-text-field
            v-model="linkShare"
           
            outlined
            dense
            readonly
            class="mr-2"
            @click:append="copyTestingCode"
          ></v-text-field>
         
              <v-btn 
                type="button" 
                text
                @click.stop.prevent="copyTestingCode"
                class=""
                
              >
                {{$t('copy')}} 
              </v-btn> 
            </template>
            <span>{{$t('copied')}}</span>
          </v-tooltip>
          <input type="hidden" id="testing-code" :value="linkShare">
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-row no-gutters class="d-flex flex-row justify-end  px-12 mx-12">
          <v-col md="10" sm="10">
            <v-btn
              color="primary"
              text
              @click="finish()"
              >{{$t('close')}} </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ShareDialog',
  data: () => ({
    dialog: false,
    linkShare: "",
    show: false,
    vacancy: {}
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("sharePartnerRole", (roleId) => {
      this.dialog = true
      this.linkShare = `https://createaccount.whirelab.com/partner.html?id=${roleId}`
      // this.linkShare = `https://webpage2.whirelab.com?id=${moduleId}`
    });
  },
  methods: {
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        document.execCommand('copy');
        this.show = !this.show
        // var msg = successful ? 'successful' : 'unsuccessful';
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    finish () {
      this.dialog = false;
      this.$emit('finish');
    }
  }
}
</script>